

import { faCalendar, faCreditCard, faLocationDot, faPeopleLine } from '@fortawesome/free-solid-svg-icons';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import BulletPoint from '../components/about/bullet';
import Layout from '../components/layout';
import PageSection from '../components/page-section';
import Seo from '../components/seo';
import ClientOnly from '../components/client-only';
import ToursList from '../components/tour-calendar-list/list/ToursList';

import { useDispatch } from "react-redux"
import { Dispatch } from "../store/store"
import { useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ToursCalendarList from '../components/tour-calendar-list/tour-calendar-list';
import ToursDaysList from '../components/tour-calendar-list/tours-days-list';
import { DateTime } from "luxon"
import { StaticImage } from 'gatsby-plugin-image';

const Tours2023TicketsAboutPage = () => {
    const { t } = useTranslation();
    // const dispatch = useDispatch<Dispatch>();
    // useEffect(() => {
    //     dispatch.toursCalendar.initialize({ translate: t, initDate: DateTime.fromISO("2023-09-16"), filterByProject: "REP_23", days: 1, });
    // }, [])

    return (
        <Layout>
            <Seo title={"Bezpłatne rejsy edukacyjno-przyrodnicze 2023"} />

            <div className="container-fluid p-0 position-relative overflow-hidden" style={{ height: "500px" }}>
                <div
                    className="position-absolute container-fluid h-100 p-0" style={{ height: "500px" }}
                >
                    <StaticImage
                        src='../images/world-cleanup-day-2023.jpg'
                        layout="fullWidth"
                        alt=""
                        formats={["auto", "webp", "avif"]}
                        placeholder="blurred"
                        transformOptions={{ fit: `cover` }}
                        style={{ height: '500px' }}
                    />
                </div>
                <div className="position-absolute container-fluid h-100 p-0 bg-primary bg-opacity-75" ></div>
                <div className="position-absolute container-fluid h-100 p-0" >
                    <div className="row h-100">
                        <div className="col-md-9">
                            <div className="container-fluid p-0 h-100 position-relative">
                                <div className="position-absolute top-50 translate-middle-y col-lg-6 col-md-8 offset-1 offset-md-2 offset-lg-3">
                                    <h1><strong>Bezpłatne rejsy</strong><br /> edukacyjno-przyrodnicze 2023</h1>
                                    <div className="py-3"></div>
                                    <h3>Rejsy w ramach World CleanUpDay (16.09)</h3>
                                    <div className="py-3"></div>
                                    <div className="pt-3">
                                        <a href="#booking-section" type="button" className="btn btn-secondary btn-lg mb-3 mb-sm-none">Rezerwuj</a>
                                        <span className="ps-3"></span>
                                        <a id="read-more-tickets" href="#more-about-tickets-section" role="button" className="btn btn-outline-light btn-lg mb-3 mb-sm-none">Czytaj więcej</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >


            <PageSection className='bg-white text-black' id="more-about-tickets-section" data-bs-spy="scroll" data-bs-target="#read-more-tickets">
                <div className="container-fluid px-4 px-md-0 py-2">
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col-sm-6">
                                    <BulletPoint icon={faLocationDot} title={"Wymagania"} >
                                        <span>Należy przybyć <strong>najpóźniej 15 min przed terminem rozpoczęcia rejsu</strong>.</span>
                                    </BulletPoint>
                                </div>
                                <div className="col-sm-6">
                                    <BulletPoint icon={faCalendar} title={"Otwarcie rezerwacji"} >
                                        <span>Otwarcie zapisów na rejsy odbędzie się 13 września 2023 o godz. 20:00</span>
                                    </BulletPoint>
                                </div>
                                <div className="col-sm-6">
                                    <BulletPoint icon={faPeopleLine} title={"Ograniczenia"} >
                                        <span>Jedna osoba może złożyć rezerwację na grupę <strong>maks. 2 osób na 1 rejs</strong>.</span>
                                    </BulletPoint>
                                </div>
                                <div className="col-sm-6">
                                    <BulletPoint icon={faCreditCard} title={"Kaucja zwrotna"} >
                                        <span>Za każdego uczestnika rejsów pobieramy kaucję zwrotną w wysokości <br /><strong>20zł /os</strong>.</span>
                                    </BulletPoint>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageSection>
            <PageSection id="booking-section" data-bs-spy="scroll" data-bs-target="#booking-section">
                <h1 className="pb-5">Zapisy online</h1>
                <div className="py-2" ></div>
                <p>Poniżej znajdują się rejsy na które można zarezerwować miejsca.</p>
                <p>Podczas rejsu o 10:00 będzie można włączyć się w akcję sprzątania Wisły.</p>
            </PageSection>
            <PageSection id="toursCalendarSection" className="bg-white text-black position-relative" style={{ minHeight: 400 }}>
                <ClientOnly>
                    <ToursDaysList project="REP_23" initDate={DateTime.fromISO("2023-09-16")} days={1} />
                </ClientOnly>
            </PageSection>
        </Layout>
    );
}

export default Tours2023TicketsAboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns:{in: ["navigation","footbar" ]},language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;